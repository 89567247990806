import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

let router = new Router({
    mode: 'hash', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'active',
    scrollBehavior: () => ({y: 0}),
    routes: configRoutes()
})


router.beforeEach((to, from, next) => {
    let roles = localStorage.getItem("roles");
    if (roles != null) {
        roles = roles.split(',')
    }
    let actionIsRole = function (roleUser, roles, to, from, next) {
        if (roles != null && roles.indexOf(roleUser) >= 0) {
            next()
        } else {
            next({
                path: '/login',
                params: {nextUrl: to.fullPath}
            })
        }
    }
    if (to.matched.some(record => record.meta.requiresAdmin)) {
        actionIsRole('admin', roles, to, from, next);
    } else if (to.matched.some(record => record.meta.requiresAdminUB)) {
        actionIsRole('admin-ub', roles, to, from, next);
    } else if (to.matched.some(record => record.meta.requiresUser)) {
        actionIsRole('user', roles, to, from, next);
    } else if (to.matched.some(record => record.meta.requiresInscripcionNuevos)) {
        actionIsRole('inscripcion-nuevos', roles, to, from, next);
    } else if (to.matched.some(record => record.meta.requiresInscripcionRegulares)) {
        actionIsRole('inscripcion-regulares', roles, to, from, next);
    } else if (to.matched.some(record => record.meta.requiresInscripcionCalendarioPagos)) {
        actionIsRole('inscripcion-calendario-pagos', roles, to, from, next);
    } else if (to.matched.some(record => record.meta.requiresFacturacion)) {
        actionIsRole('facturacion', roles, to, from, next);
    } else if (to.matched.some(record => record.meta.requiresFacturacionManual)) {
        actionIsRole('facturacion-manual', roles, to, from, next);
    } else if (to.matched.some(record => record.meta.requiresFacturacionEnLinea)) {
        actionIsRole('facturacion-en-linea', roles, to, from, next);
    } else if (to.matched.some(record => record.meta.requiresFacturacionEnLineaManual)) {
        actionIsRole('facturacion-en-linea-manual', roles, to, from, next);
    } else if (to.matched.some(record => record.meta.requiresDocumentos)) {
        actionIsRole('documentos', roles, to, from, next);
    } else if (to.matched.some(record => record.meta.requiresCompromisos)) {
        actionIsRole('compromisos', roles, to, from, next);
    } else if (to.matched.some(record => record.meta.requiresContratos)) {
        actionIsRole('contratos', roles, to, from, next);
    } else if (to.matched.some(record => record.meta.requiresHojaruta)) {
        actionIsRole('hojaruta', roles, to, from, next);
    } else if (to.matched.some(record => record.meta.requiresReportesBoletin)) {
        actionIsRole('rpt_boletin_calificaciones', roles, to, from, next);
    } else if (to.matched.some(record => record.meta.requiresReporteBoletaExamen)) {
        actionIsRole('rpt_boleta_examen', roles, to, from, next);
    }else if (to.matched.some(record => record.meta.requiresReporteshistorialacademico)) {
        actionIsRole('rpt_historial_academico', roles, to, from, next);
    }  else if (to.matched.some(record => record.meta.requiresReportesCertNotas)) {
        actionIsRole('rpt_certificado_notas', roles, to, from, next);
    } else if (to.matched.some(record => record.meta.requiresReporteTituloProfesional)) {
        actionIsRole('rpt_diploma_academico', roles, to, from, next);
    }  else if (to.matched.some(record => record.meta.requiresReportePlanillaCalificaciones)) {
        actionIsRole('rpt_planilla_calificaciones', roles, to, from, next);
    } else if (to.matched.some(record => record.meta.requiresReportesFacturacion)) {
        actionIsRole('rpt_facturacion', roles, to, from, next);
    } else if (to.matched.some(record => record.meta.requiresReportesPagosEstudiantes)) {
        actionIsRole('rpt_pagos_estudiante', roles, to, from, next);
    } else if (to.matched.some(record => record.meta.requiresReportesMatriculadosGestion)) {
        actionIsRole('rpt_matriculados_gestion', roles, to, from, next);
    } else if (to.matched.some(record => record.meta.requiresReportesEstudiantesMateria)) {
        actionIsRole('rpt_estudiantes_materia', roles, to, from, next);
    } else if (to.matched.some(record => record.meta.requiresReportesEstudiantesBecas)) {
        actionIsRole('rpt_estudiantes_beca', roles, to, from, next);
    } else if (to.matched.some(record => record.meta.requiresDocenteNotasAdministrador)) {
        actionIsRole('docente-notas-administrador', roles, to, from, next);
    } else if (to.matched.some(record => record.meta.requiresDocenteDesignaMateria)) {
        actionIsRole('docente-designa-materia', roles, to, from, next);
    } else if (to.matched.some(record => record.meta.requiresDocenteRegistroNotas)) {
        actionIsRole('docente-notas', roles, to, from, next);
    } else if (to.matched.some(record => record.meta.requiresDocenteAdministrador)) {
        actionIsRole('docente-administrador', roles, to, from, next);
    } else if (to.matched.some(record => record.meta.requiresEstudiante)) {
        actionIsRole('estudiantes', roles, to, from, next);
    } else if (to.matched.some(record => record.meta.requiresCredencial)) {
        actionIsRole('credencial', roles, to, from, next);
    } else if (to.matched.some(record => record.meta.requiresInscripcionCalendarioPagos)) {
        actionIsRole('inscripcion-calendario-pagos', roles, to, from, next);
    } else if (to.matched.some(record => record.meta.requiresBecasConfiguracion)) {
        actionIsRole('becas_configuracion', roles, to, from, next);
    } else if (to.matched.some(record => record.meta.requiresUsuarioAdministrador)) {
        actionIsRole('usuarios_administrador', roles, to, from, next);
    } else {
        next()
    }
})

export default router

function configRoutes() {
    return [
        {
            path: '/',
            redirect: '/dashboard',
            name: 'Home',
            component: () => import('@/containers/TheContainer'),
            meta: {
                auth: true
            },
            children: [
                {
                    path: 'media',
                    name: 'Media',
                    component: () => import('@/views/media/Media'),
                    meta: {
                        requiresAdmin: true
                    }
                },
                {
                    path: 'dashboard',
                    name: 'Dashboard',
                    component: () => import('@/views/Dashboard')
                },
                {
                    path: 'menu',
                    meta: {label: 'Menu'},
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            component: () => import('@/views/menu/MenuIndex'),
                            meta: {
                                requiresAdmin: true
                            }
                        },
                        {
                            path: 'create',
                            name: 'CreateMenu',
                            component: () => import('@/views/menu/CreateMenu'),
                            meta: {
                                requiresAdmin: true
                            }
                        },
                        {
                            path: ':id/edit',
                            name: 'EditMenu',
                            component: () => import('@/views/menu/EditMenu'),
                            meta: {
                                requiresAdmin: true
                            }
                        },
                        {
                            path: ':id/delete',
                            name: 'DeleteMenu',
                            component: () => import('@/views/menu/DeleteMenu'),
                            meta: {
                                requiresAdmin: true
                            }
                        },
                    ]
                },{
                    path: 'materias',
                    meta: {label: 'Materias'},
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            component: () => import('@/views/materias/index'),
                            meta: {
                                requiresAdmin: true
                            }
                        },
                    ]
                },{
                    path: 'facultad',
                    meta: {label: 'Facultades'},
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            component: () => import('@/views/facultad/index'),
                            meta: {
                                requiresAdmin: true
                            }
                        },
                        {
                            path: ':id/editar',
                            component: () => import('@/views/facultad/editar'),
                            meta: {
                                requiresAdmin: true
                            }
                        },
                    ]
                },{
                    path: 'carrera',
                    meta: {label: 'Carreras'},
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            component: () => import('@/views/carrera/index'),
                            meta: {
                                requiresAdmin: true
                            }
                        },
                        {
                            path: ':id/editar',
                            component: () => import('@/views/carrera/editar'),
                            meta: {
                                requiresAdmin: true
                            }
                        },
                    ]
                },
                {
                    path: 'menuelement',
                    meta: {label: 'MenuElement'},
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: ':menu/menuelement',
                            component: () => import('@/views/menuElements/ElementsIndex'),
                            meta: {
                                requiresAdmin: true
                            }
                        },
                        {
                            path: ':menu/menuelement/create',
                            name: 'Create Menu Element',
                            component: () => import('@/views/menuElements/CreateMenuElement'),
                            meta: {
                                requiresAdmin: true
                            }
                        },
                        {
                            path: ':menu/menuelement/:id',
                            name: 'Menu Element',
                            component: () => import('@/views/menuElements/ShowMenuElement'),
                            meta: {
                                requiresAdmin: true
                            }
                        },
                        {
                            path: ':menu/menuelement/:id/edit',
                            name: 'Edit Menu Element',
                            component: () => import('@/views/menuElements/EditMenuElement'),
                            meta: {
                                requiresAdmin: true
                            }
                        },
                        {
                            path: ':menu/menuelement/:id/delete',
                            name: 'Delete Menu Element',
                            component: () => import('@/views/menuElements/DeleteMenuElement'),
                            meta: {
                                requiresAdmin: true
                            }
                        },
                    ]
                },
                {
                    path: 'users',
                    meta: {label: 'Users'},
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            component: () => import('@/views/users/Users'),
                            meta: {
                                requiresUsuarioAdministrador: true
                            }
                        },
                        {
                            path: ':id',
                            name: 'User',
                            component: () => import('@/views/users/User'),
                            meta: {
                                requiresUsuarioAdministrador: true
                            }
                        },
                        {
                            path: ':id/edit',
                            name: 'Edit User',
                            component: () => import('@/views/users/EditUser'),
                            meta: {
                                requiresUsuarioAdministrador: true
                            }
                        },
                    ]
                },
                {
                    path: 'credencial',
                    meta: {label: 'Credencial'},
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/',
                            name: 'Credenciales',
                            component: () => import('@/views/credencial/index'),
                            meta: {
                                requiresCredencial: true,
                            }
                        }
                    ]
                },
                {
                    path: 'inscripcion',
                    meta: {label: 'Inscripción'},
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: 'alumnos',
                            name: 'Alumnos',
                            component: () => import('@/views/inscripcion/Alumnos'),
                            meta: {
                                requiresInscripcionNuevos: true,
                            }
                        }, {
                            path: 'nuevo',
                            name: 'Alumno nuevo',
                            component: () => import('@/views/inscripcion/nuevo'),
                            meta: {
                                requiresInscripcionNuevos: true
                            }
                        }, {
                            path: ':id/:codigo_alumno/editar',
                            name: 'editar',
                            component: () => import('@/views/inscripcion/editar'),
                            meta: {
                                requiresInscripcionNuevos: true
                            }
                        },

                        {
                            path: 'antiguos',
                            name: 'Alumnos Regulares',
                            component: () => import('@/views/inscripcion/antiguos'),
                            meta: {
                                requiresInscripcionRegulares: true
                            }
                        }, {
                            path: ':id/:codigo_alumno/editarantiguo',
                            name: 'Editar Alumno Regular',
                            component: () => import('@/views/inscripcion/editarantiguo'),
                            meta: {
                                requiresInscripcionRegulares: true
                            }
                        },

                        {
                            path: 'calendariopagos',
                            name: 'Calendario de Pagos',
                            component: () => import('@/views/inscripcion/calendariopagos'),
                            meta: {
                                requiresInscripcionCalendarioPagos: true
                            }
                        },

                        {
                            path: '/',
                            name: 'Comrpmisos',
                            component: () => import('@/views/inscripcion/index'),
                            meta: {
                                requiresCompromisos: true
                            }
                        }, {
                            path: ':id/:codigo_alumno/compromisos',
                            name: 'Compromisos',
                            component: () => import('@/views/inscripcion/compromisos'),
                            meta: {
                                requiresCompromisos: true
                            }
                        }, {
                            path: ':id/documentos',
                            name: 'documentos',
                            component: () => import('@/views/inscripcion/documentos'),
                            meta: {
                                requiresDocumentos: true
                            }
                        }, {
                            path: ':id/:codigo_alumno/:gestion/contrato',
                            name: 'contrato',
                            component: () => import('@/views/inscripcion/contrato'),
                            meta: {
                                requiresContratos: true
                            }
                        },
                        {
                            path: 'becasconfiguracion',
                            name: 'Configuracion de becas',
                            component: () => import('@/views/inscripcion/becasconfiguracion.vue'),
                            meta: {
                                requiresBecasConfiguracion: true
                            }
                        },
                    ]
                },
                {
                    path: 'estudiante',
                    meta: {label: 'Estudiante'},
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/',
                            name: 'estudiantes',
                            component: () => import('@/views/estudiante/index'),
                            meta: {
                                requiresEstudiante: true
                            }
                        },
                        {
                            path: ':codigo_alumno/password',
                            name: 'estudiantePassword',
                            component: () => import('@/views/estudiante/password'),
                            meta: {
                                requiresEstudiante: true
                            }
                        }
                    ]
                },
                {
                    path: 'docente',
                    meta: {label: 'Docente'},
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/',
                            name: 'docentes',
                            component: () => import('@/views/docente/index'),
                            meta: {
                                requiresDocenteAdministrador: true
                            }
                        },
                        {
                            path: 'nuevo',
                            name: 'Docentenuevo',
                            component: () => import('@/views/docente/nuevo'),
                            meta: {
                                requiresDocenteAdministrador: true
                            }
                        },
                        {
                            path: ':id/editar',
                            name: 'Docenteeditar',
                            component: () => import('@/views/docente/editar'),
                            meta: {
                                requiresDocenteAdministrador: true
                            }
                        },
                        {
                            path: ':id/password',
                            name: 'DocentePassword',
                            component: () => import('@/views/docente/password'),
                            meta: {
                                requiresDocenteAdministrador: true
                            }
                        }
                    ]
                },
                {
                    path: 'docentemateria',
                    meta: {label: 'Docente Materias'},
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: ':id/',
                            name: 'docentemateria',
                            component: () => import('@/views/docentemateria/index'),
                            meta: {
                                requiresDocenteMateria: true
                            }
                        },
                        {
                            path: ':id/nuevo',
                            name: 'DocenteMateriaNuevo',
                            component: () => import('@/views/docentemateria/nuevo'),
                            meta: {
                                requiresDocenteMateria: true
                            }
                        },
                        {
                            path: ':docenteid/:id/editar',
                            name: 'DocenteMateriaEditar',
                            component: () => import('@/views/docentemateria/editar'),
                            meta: {
                                requiresDocenteMateria: true
                            }
                        },
                        {
                            path: ':docenteid/:id/eliminar',
                            name: 'DocenteMateriaEliminar',
                            component: () => import('@/views/docentemateria/editar'),// TODO revisar eliminar materia docente
                            meta: {
                                requiresDocenteMateria: true
                            }
                        }
                    ]
                },
                {
                    path: 'docentematerianotas',
                    meta: {label: 'Docente Materias Notas'},
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/',
                            name: 'docentematerianotas',
                            component: () => import('@/views/docentematerianotas/index'),
                            meta: {
                                requiresDocenteNotasAdministrador: true
                            }
                        },
                        {
                            path: ':sede_id/:gestion/:carrera_id/:plan_id/:materia_id/:grupo/:parcial/parcialnuevo',
                            name: 'DocenteMateriaParcialNuevo',
                            component: () => import('@/views/docentematerianotas/parcialnuevo'),
                            meta: {
                                requiresDocenteNotasAdministrador: true
                            }
                        }
                    ]
                },
                {
                    path: 'docentedesigmateria',
                    meta: {label: 'Docente Designacion de Materias'},
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/',
                            name: 'docentedesigmateria',
                            component: () => import('@/views/docentedesigmateria/antiguos'),
                            meta: {
                                requiresDocenteDesignaMateria: true
                            }
                        },{
                            path: ':id/:codigo_alumno/editarantiguo',
                            name: 'Designar Materias Alumno Regular',
                            component: () => import('@/views/docentedesigmateria/editarantiguo'),
                            meta: {
                                requiresDocenteDesignaMateria: true
                            }
                        },
                    ]
                },
                {
                    path: 'docenteregistronotas',
                    meta: {label: 'Docente Registro Notas'},
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/',
                            name: 'docenteregistronotas',
                            component: () => import('@/views/docenteregistronotas/index'),
                            meta: {
                                requiresDocenteRegistroNotas: true
                            }
                        },
                        {
                            path: ':sede_id/:gestion/:carrera_id/:plan_id/:materia_id/:grupo/:parcial/parcialnuevo',
                            name: 'DocenteRegistroParcialNuevo',
                            component: () => import('@/views/docenteregistronotas/parcialnuevo'),
                            meta: {
                                requiresDocenteRegistroNotas: true
                            }
                        }
                    ]
                },
                {
                    path: 'docentehistorial',
                    meta: {label: 'Docente Historial'},
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/',
                            name: 'historialacademicoeditar',
                            component: () => import('@/views/docentehistorial/historialacademico'),
                            meta: {
                                requiresDocenteRegistroNotas: true
                            }
                        },
                    ]
                },
                {
                    path: 'docentereportes',
                    meta: {label: 'Docente Reportes'},
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/',
                            name: 'DocenteReportesIndex',
                            component: () => import('@/views/docentereportes/index'),
                            meta: {
                                requiresReportes: true
                            }
                        },
                        {
                            path: 'boletin',
                            name: 'docenterptboletin',
                            component: () => import('@/views/docentereportes/boletincalificaciones'),
                            meta: {
                                requiresReportesBoletin: true
                            }
                        },
                        {
                            path: 'certificadosnotas',
                            name: 'certificadosnotas',
                            component: () => import('@/views/docentereportes/certificadosnotas'),
                            meta: {
                                requiresReportesCertNotas: true
                            }
                        },
                        {
                            path: 'historialacademico',
                            name: 'historialacademico',
                            component: () => import('@/views/docentereportes/historialacademico'),
                            meta: {
                                requiresReporteshistorialacademico: true
                            }
                        },
                        {
                            path: 'boletaexamen',
                            name: 'boletaexamen',
                            component: () => import('@/views/docentereportes/boletaexamen'),
                            meta: {
                                requiresReporteBoletaExamen: true
                            }
                        },
                        {
                            path: 'planillacalificaciones',
                            name: 'planillacalificaciones',
                            component: () => import('@/views/docentereportes/planillacalificaciones'),
                            meta: {
                                requiresReportePlanillaCalificaciones: true
                            }
                        },
                        {
                            path: 'tituloprofesional',
                            name: 'tituloprofesional',
                            component: () => import('@/views/docentereportes/tituloprofesional'),
                            meta: {
                                requiresReporteTituloProfesional: true
                            }
                        },
                    ]
                },
                {
                    path: 'facturaenlinea',
                    meta: {label: 'Facturas'},
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: 'create',
                            name: 'Factura En líneea nueva',
                            component: () => import('@/views/facturaenlinea/create.vue'),
                            meta: {
                                requiresFacturacionEnLinea: true
                            }
                        }, {
                            path: '/',
                            name: 'Facturas en línea',
                            component: () => import('@/views/facturaenlinea/index.vue'),
                            meta: {
                                requiresFacturacionEnLinea: true
                            }
                        },
                    ]
                },
                {
                    path: 'facturaenlineaexterna',
                    meta: {label: 'Facturas En linea Externas'},
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: 'create',
                            name: 'facturaenlineaexterna nueva',
                            component: () => import('@/views/facturaenlineaexterna/create.vue'),
                            meta: {
                                requiresFacturacionEnLinea: true
                            }
                        }, {
                            path: '/',
                            name: 'facturaenlineaexterna',
                            component: () => import('@/views/facturaenlineaexterna/index.vue'),
                            meta: {
                                requiresFacturacionEnLinea: true
                            }
                        },
                    ]
                },
                {
                    path: 'facturaenlineamanual',
                    meta: {label: 'Facturas en linea manuales'},
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: 'create',
                            name: 'Factura En línea Manual nueva',
                            component: () => import('@/views/facturaenlineamanual/create.vue'),
                            meta: {
                                requiresFacturacionEnLineaManual: true
                            }
                        }, {
                            path: '/',
                            name: 'Facturas en línea Manual',
                            component: () => import('@/views/facturaenlineamanual/index.vue'),
                            meta: {
                                requiresFacturacionEnLineaManual: true
                            }
                        },
                    ]
                },
                {
                    path: 'factura',
                    meta: {label: 'Facturas'},
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: 'create',
                            name: 'Factura nueva',
                            component: () => import('@/views/factura/create'),
                            meta: {
                                requiresFacturacion: true
                            }
                        }, {
                            path: 'reporte',
                            name: 'Reporte Facturación',
                            component: () => import('@/views/factura/reporte'),
                            meta: {
                                requiresReportesFacturacion: true
                            }
                        }, {
                            path: '/',
                            name: 'Facturas',
                            component: () => import('@/views/factura/index'),
                            meta: {
                                requiresFacturacion: true
                            }
                        },
                    ]
                },
                {
                    path: 'facturaexterna',
                    meta: {label: 'Facturas Externas'},
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: 'create',
                            name: 'Factura Externa nueva',
                            component: () => import('@/views/facturaexterna/create'),
                            meta: {
                                requiresFacturacion: true
                            }
                        }, {
                            path: '/',
                            name: 'Facturas Externas',
                            component: () => import('@/views/facturaexterna/index'),
                            meta: {
                                requiresFacturacion: true
                            }
                        },
                    ]
                },
                {
                    path: 'facturamanual',
                    meta: {label: 'Facturación Manual de Estudiantes'},
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: 'create',
                            name: 'Factura Manual Nueva',
                            component: () => import('@/views/facturamanual/create'),
                            meta: {
                                requiresFacturacionManual: true
                            }
                        }, {
                            path: '/',
                            name: 'Facturas Manuales',
                            component: () => import('@/views/facturamanual/index'),
                            meta: {
                                requiresFacturacionManual: true
                            }
                        },
                    ]
                },
                {
                    path: 'facturamanualexterna',
                    meta: {label: 'Facturación Manual Externa'},
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: 'create',
                            name: 'Nueva Factura Manual Externa',
                            component: () => import('@/views/facturamanualexterna/create'),
                            meta: {
                                requiresFacturacionManual: true
                            }
                        }, {
                            path: '/',
                            name: 'Facturas Manuales Externas',
                            component: () => import('@/views/facturamanualexterna/index'),
                            meta: {
                                requiresFacturacionManual: true
                            }
                        },
                    ]
                },
                {
                    path: 'reporte',
                    name: 'Reporte',
                    component: () => import('@/views/reporte/index'),
                    meta: {
                        requiresReportesMatriculadosGestion: true
                    },
                },
                {
                    path: 'reporte/estudiantes',
                    name: 'Reporte Estudiantes',
                    component: () => import('@/views/reporte/estudiantes'),
                    meta: {
                        requiresReportesEstudiantesMateria: true
                    },
                },
                {
                    path: 'reporte/estudiantesdatos',
                    name: 'Reporte Datos de Estudiantes',
                    component: () => import('@/views/reporte/estudiantesdatos'),
                    meta: {
                        requiresReportesEstudiantesMateria: true
                    },
                },
                {
                    path: 'reporte/documentosfaltantes',
                    name: 'Reporte Documentos Faltantes',
                    component: () => import('@/views/reporte/documentosfaltantes'),
                    meta: {
                        requiresReportesEstudiantesMateria: true
                    },
                },
                {
                    path: 'reporte/estudiantesbecas',
                    name: 'Reporte Estudiantes por Becas',
                    component: () => import('@/views/reporte/estudiantesbecas'),
                    meta: {
                        requiresReportesEstudiantesBecas: true
                    },
                }, {
                    path: 'reporte/pagoestudiante',
                    name: 'Reporte Pagos por estudiante',
                    component: () => import('@/views/reporte/pagoestudiante'),
                    meta: {
                        requiresReportesPagosEstudiantes: true
                    }
                },
                {
                    path: 'roles',
                    meta: {label: 'Roles'},
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            component: () => import('@/views/roles/Roles'),
                            meta: {
                                requiresAdmin: true
                            }
                        },
                        {
                            path: 'create',
                            name: 'Create Role',
                            component: () => import('@/views/roles/CreateRole'),
                            meta: {
                                requiresAdmin: true
                            }
                        },
                        {
                            path: ':id',
                            name: 'Role',
                            component: () => import('@/views/roles/Role'),
                            meta: {
                                requiresAdmin: true
                            }
                        },
                        {
                            path: ':id/edit',
                            name: 'Edit Role',
                            component: () => import('@/views/roles/EditRole'),
                            meta: {
                                requiresAdmin: true
                            }
                        },
                    ]
                },
                {
                    path: 'bread',
                    meta: {label: 'Bread'},
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            component: () => import('@/views/bread/Breads'),
                            meta: {
                                requiresAdmin: true
                            }
                        },
                        {
                            path: 'create',
                            name: 'CreateBread',
                            component: () => import('@/views/bread/CreateBread'),
                            meta: {
                                requiresAdmin: true
                            }
                        },
                        {
                            path: ':id',
                            name: 'Bread',
                            component: () => import('@/views/bread/Bread'),
                            meta: {
                                requiresAdmin: true
                            }
                        },
                        {
                            path: ':id/edit',
                            name: 'Edit Bread',
                            component: () => import('@/views/bread/EditBread'),
                            meta: {
                                requiresAdmin: true
                            }
                        },
                        {
                            path: ':id/delete',
                            name: 'Delete Bread',
                            component: () => import('@/views/bread/DeleteBread'),
                            meta: {
                                requiresAdmin: true
                            }
                        },
                    ]
                },
                {
                    path: 'resource',
                    meta: {label: 'Resources'},
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: ':bread/resource',
                            component: () => import('@/views/resources/Resources'),
                        },
                        {
                            path: ':bread/resource/create',
                            meta: {label: 'Create Resource'},
                            name: 'CreateResource',
                            component: () => import('@/views/resources/CreateResources')
                        },
                        {
                            path: ':bread/resource/:id',
                            meta: {label: 'Resource Details'},
                            name: 'Resource',
                            component: () => import('@/views/resources/Resource'),
                        },
                        {
                            path: ':bread/resource/:id/edit',
                            meta: {label: 'Edit Resource'},
                            name: 'Edit Resource',
                            component: () => import('@/views/resources/EditResource')
                        },
                        {
                            path: ':bread/resource/:id/delete',
                            meta: {label: 'Delete Resource'},
                            name: 'Delete Resource',
                            component: () => import('@/views/resources/DeleteResource')
                        },
                    ]
                },
                {
                    path: 'notifications',
                    redirect: '/notifications/alerts',
                    name: 'Notifications',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: 'alerts',
                            name: 'Alerts',
                            component: () => import('@/views/notifications/Alerts'),
                            meta: {
                                requiresUser: true
                            }
                        },
                        {
                            path: 'badge',
                            name: 'Badge',
                            component: () => import('@/views/notifications/Badges'),
                            meta: {
                                requiresUser: true
                            }
                        },
                        {
                            path: 'modals',
                            name: 'Modals',
                            component: () => import('@/views/notifications/Modals'),
                            meta: {
                                requiresUser: true
                            }
                        }
                    ]
                },
            ]
        },
        {
            path: '/pages',
            redirect: '/pages/404',
            name: 'Pages',
            component: {
                render(c) {
                    return c('router-view')
                }
            },
            children: [
                {
                    path: '404',
                    name: 'Page404',
                    component: () => import('@/views/pages/Page404')
                },
                {
                    path: '500',
                    name: 'Page500',
                    component: () => import('@/views/pages/Page500')
                },
            ]
        },
        {
            path: '/',
            redirect: '/login',
            name: 'Auth',
            component: {
                render(c) {
                    return c('router-view')
                }
            },
            children: [
                {
                    path: 'login',
                    name: 'Login',
                    component: () => import('@/views/pages/Login')
                },
                {
                    path: 'register',
                    name: 'Register',
                    component: () => import('@/views/pages/Register'),
                    meta: {
                        requiresAdmin: true
                    }
                },
            ]
        },
        {
            path: '*',
            name: '404',
            component: () => import('@/views/pages/Page404')
        }
    ]
}
