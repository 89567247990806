import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import vSelect from 'vue-select'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faFileExcel,faPlus,faSortAmountUpAlt,faFileInvoice,faXmark,faCircleQuestion,
    faVideo,faTimesCircle,faCheckCircle,faGear,faPowerOff,faPencil, faCloudArrowDown,faUser,faLock,faEye,faEyeSlash
} from '@fortawesome/free-solid-svg-icons'
import { faFilePdf, faFileWord
} from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(
    faFileExcel,faPlus,faSortAmountUpAlt,faFilePdf,faFileInvoice,faXmark,faCircleQuestion,
    faVideo,faTimesCircle,faCheckCircle,faGear,faPowerOff,faPencil, faCloudArrowDown,
    faFileWord, faUser,faLock,faEye,faEyeSlash
)

// desarrollo
// Vue.prototype.$apiAdress = 'http://127.0.0.1:8000'
 Vue.prototype.$apiAdress = '/backend'
// pruebas
// Vue.prototype.$apiAdress = 'https://ubpruebas.vgm.tecnologia.bo/backend'
// produccion
// Vue.prototype.$apiAdress = 'https://ubacademico.vgm.tecnologia.bo/backend'
Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('v-select', vSelect)

new Vue({
  el: '#app',
  router,
  store,  
  icons,
  template: '<App/>',
  components: {
    App
  },
})
